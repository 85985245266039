<template>
  <div class="content">
    <div class="top">
      <div class="title">
        <img style="width: calc(100vw * 370 / 1920);height: calc(100vw * 168 / 1920);" src="@/assets/img1/logo.png"
          alt="">
      </div>
      <div class="logo">
        <img style="width: calc(100vw * 161 / 1920);height: calc(100vw * 59 / 1920);" src="@/assets/img/gsLOGO.png"
          alt="">
      </div>
    </div>

    <div class="main">
      <template v-for="(item, index) in prompt">
        <!-- <div> -->
        <div class="left1" v-if="item.role == 'assistant'">
          <!-- <img style="margin-right: 30px;" src="@/assets/img/avatar1.png" alt=""> -->
          <div class="left2">
            <div class="left" v-html="nr(item.content)">

            </div>
          </div>
        </div>

        <div class="right1" v-if="item.role == 'user'">
          <div class="right2">
            <div class="right">
              {{ filtration(item.content) }}
            </div>
          </div>
          <!-- <img style="margin-left: 30px;" src="@/assets/img/avatar2.png" alt="" > -->

        </div>
        <!-- </div> -->

      </template>
      <div class="left1" v-if="event == 'add'">
        <div class="left2">
          <div class="left" v-html="markdownToHtml">

          </div>
        </div>
      </div>

    </div>

    <div class="bottom">
      <!-- <div class="left"> -->
      <img @click.stop="help" style="width: calc(100vw * 288 / 1920);height: calc(100vw * 81 / 1920);"
        src="@/assets/img1/pop.png" alt="">
      <!-- </div> -->
      <div class="center">
        <img @click="ask" id="startBtn" style="width: calc(100vw * 114 / 1920);height: calc(100vw * 114 / 1920);"
          v-show="!flag" src="@/assets/img1/voice.png" alt="">
        <div class="zt">语音识别</div>
      </div>
      <!-- <div class="right"> -->
      <img class="clear" @click="clearHistory" style="width: calc(100vw * 189 / 1920);height: calc(100vw * 60 / 1920);"
        src="@/assets/img1/btn.png" alt="">

      <!-- </div> -->
    </div>
    <div class="mask" v-show="flag">
      <div class="mask1">
        <div style="margin-left: calc(100vw * 80 / 1920);">
          <div class="tips">
            <img src="@/assets/img/tips.png" alt="">
            <span>点击确定后即刻反馈结果</span>
          </div>
          <div class="top">
            {{ content }}
            <div>正在识别中...</div>
            <div class="countdown">{{ countdown }}</div>
          </div>
          <div class="btn" id="endBtn">
            <img @click="cancelBtn" ref="endBtn" style="width: calc(100vw * 69 / 1920);height: calc(100vw * 28 / 1920);"
              src="@/assets/img/cancel.png" alt="">
            <img @click="confirmBtn" style="width: calc(100vw * 67 / 1920);height: calc(100vw * 28 / 1920);"
              src="@/assets/img/conform.png" alt="">
          </div>
        </div>
        <div class="img">
          <!-- <img style="width: calc(100vw * 114 / 1920);height: calc(100vw * 114 / 1920);" src="@/assets/img1/voice_pic.png" alt=""> -->
          <img @click.stop="help" style="width: calc(100vw * 288 / 1920);height: calc(100vw * 81 / 1920);opacity: 0;"
            src="@/assets/img1/pop.png" alt="">
          <!-- </div> -->
          <div class="center">
            <img style="width: calc(100vw * 114 / 1920);height: calc(100vw * 114 / 1920);"
              src="@/assets/img1/voice_pic.png" alt="">
            <div class="zt" style="opacity: 0;">1</div>
          </div>
          <!-- <div class="right"> -->
          <img class="clear" @click="clearHistory"
            style="width: calc(100vw * 189 / 1920);height: calc(100vw * 60 / 1920);opacity: 0;"
            src="@/assets/img1/btn.png" alt="">
        </div>
      </div>
    </div>
    <div class="maskFlag" v-if="maskFlag">
      <div class="mask1">
        <div class="img">
          <img style="width: calc(100vw * 100 / 1920);height: calc(100vw * 100 / 1920);" src="@/assets/img/avatar1.png"
            alt="">
        </div>
        <div class="keywords">
          <div>可以通过语句向我描述要求，生成藏头诗</div>
          <div class="two">如：请用<span>平湖秋月</span>写一首藏头诗</div>
        </div>
        <div class="confirm" @click="okBtn">好的</div>
      </div>
    </div>
  </div>
</template>

<script>
import minix from '@/minix'
export default {
  mixins: [minix],
  data() {
    return {
      maskFlag: false,
      flag: false,
      eventSource: null,
      message: '',
      content: '',
      signSucc: '',
      prompt: [
      ],
      event: '',
    }
  },
  destroyed() {
    if(this.signSucc){
      if(this.plays){
        this.plays.then(res=>{
            this.signSucc.pause();
          })
        }
      }
  },
  mounted() {
    this.audioInit()
  },
  methods: {

  },
}
</script>
<style lang="scss">
@import '@/assets/css/style.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
}

.maskFlag {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: .6);
  display: flex;
  align-items: center;
  justify-content: center;

  .mask1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40%;
    background: linear-gradient(180deg, #D4AA77 0%, #E2C69F 100%);
    border-radius: 25px 25px 25px 25px;
    opacity: 1;
    border: 3px solid #FFFFFF;
    position: relative;
    padding: calc(100vw * 50 / 1920) 0;

    .img {
      position: absolute;
      top: -30%;
      left: 50%;
      transform: translate(-50%, 30%);
      border: 5px solid #fff;
      border-radius: 50%;
    }

    .keywords {
      margin-top: 4%;
      font-weight: 800;
      color: #412C16;
      font-family: 'shuzhan';
      font-size: calc(100vw * 28 / 1920);
      text-align: center;

      .two {
        font-weight: 800;
        color: #412C16;
        line-height: 47px;

        span {
          color: #CC501A
        }
      }
    }

    .confirm {
      margin-top: 5%;
      width: calc(100vw * 124 / 1920);
height: calc(100vw * 54 / 1920);
      background: linear-gradient(319deg, #BB7F43 0%, #D29C6D 100%);
      border-radius: 40px 40px 40px 40px;
      opacity: 1;
      text-align: center;
      font-size: calc(100vw * 28 / 1920);
      font-family: 'shuzhan';
      font-weight: 800;
      color: #412C16;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #000000, $alpha: .6);
  display: flex;
  justify-content: center;

  .mask1 {
    padding: calc(100vw * 45 / 1920) calc(100vw * 87.5 / 1920);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    bottom: 0;

    // transform: translate(-50%, 0);
    .top {
      font-size: calc(100vw * 24 / 1920);
      font-family: 'shuzhan';
      padding: calc(100vw * 60 / 1920);
      min-width: calc(100vw * 593 / 1920);
      max-width: 100%;
      min-height: calc(100vw * 157 / 1920);
      background: linear-gradient(319deg, #DCC2A8 0%, #F3E1C6 100%);
      border-radius:calc(100vw * 20 / 1920);
      opacity: 1;
      position: relative;
      color: #082348;

      div {
        position: absolute;
        bottom: 0;
        right: 0;
        font-family: 'shuzhan';
        padding: calc(100vw * 14 / 1920) calc(100vw * 25 / 1920);
        font-size: calc(100vw * 15 / 1920);
        color: #412C16;
      }
    }

    .btn {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        margin: 15px 20px;
      }
    }

    .img {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}

.content {
  font-family: 'shuzhan';
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  padding: calc(100vw * 45 / 1920) calc(100vw * 87.5 / 1920);
  background: url('@/assets/img1/underpic.png') no-repeat;
  background-size: 100% 100%;
  position: absolute;
  filter: progid:DXImageTransform.Microsoft.AlphaImageLoader(src='bg-login.png', sizingMethod='scale');
}

.top {
  // margin-top: 45px;
  height: 15%;
  width: 100%;
  position: relative;

  .title {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
  }

  .logo {
    position: absolute;
    right: 30px;
    top: 0;
  }
}

.main::-webkit-scrollbar {
  display: none;
}

.main {
  flex: 1;
  // height: 80%;
  font-family: 'shuzhan';
  overflow-y: scroll;

  .left1 {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
  }

  .left2 {
    border-radius: 15px 15px 15px 0px;
    opacity: 1;
    border: 3px solid #B89774;
    max-width: 45%;
  }

  .left {
    display: flex;
    justify-content: flex-start;
    background: linear-gradient(270deg, #CDB496 0%, #C6A989 100%);
    border-radius: 10px 10px 10px 0px;
    padding: 20px 42px;
    opacity: 1;
    min-height: calc(100vw * 86 / 1920);
    font-size: calc(100vw * 33 / 1920);

    color: #412C16;
    line-height: calc(100vw * 53 / 1920);
    margin: 2px;
    overflow: auto;
  }

  .right1 {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

  }

  .right2 {
    border-radius: 15px 15px 0px 15px;
    opacity: 1;
    border: 3px solid #B89774;
    max-width: 45%;
  }

  .right {

    display: flex;
    justify-content: flex-end;
    padding: 20px 42px;
    background: linear-gradient(270deg, #CDB496 0%, #C6A989 100%);
    border-radius: 10px 10px 0px 10px;
    opacity: 1;
    min-height: calc(100vw * 86 / 1920);
    font-size: calc(100vw * 33 / 1920);

    color: #412C16;
    line-height: calc(100vw * 53 / 1920);

    margin: calc(100vw * 2 / 1920);
  }

  .stream {}
}

.bottom {
  height: calc(100vw * 171 / 1920);
  width: 100%;
  // height: 15%;
  display: flex;
  justify-content: space-between;
  align-items: end;

  .clear {
    cursor: pointer;
  }
}

.zt {
  text-align: center;
  font-weight: 800;
  color: #412C16;
  line-height: calc(100vw * 31 / 1920);
  font-family: 'shuzhan';
  font-size: calc(100vw * 27 / 1920);
}

.tips {
  display: flex;
  font-family: 'sc_regular';
  align-items: center;

  img {
    width: calc(100vw * 32 / 1920);
    height: calc(100vw * 32 / 1920);
  }

  span {
    color: #FFFFFF;
    line-height: calc(100vw * 37 / 1920);
    font-size: calc(100vw * 20 / 1920);
  }
}
.countdown{
  position: absolute;
  top: 0;
  right: calc(100vw * 20 / 1920);
}</style>